<template>
  <div class="bookmarks">
    <v-container>
      <v-row>
        <v-col>
          <h2 class="grey--text">Requirements</h2>
          <div>
            <div v-for="req of requirements" :key="req.uid">
              <h3>{{ req.uid }} : {{ req.title }} </h3>
              <div>{{ req.rating}}: {{ req.evaluation}}</div>
              <div>{{ req.center.title }}</div>
            </div>
            {{ requirements }}
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>

/* --- local database ---- */
import Localbase from 'localbase'
const zozDB = new Localbase('zozDB')
zozDB.config.debug = false


export default {
  name: 'Test',
  data: function() {
    return {
      requirements: [],
    }
  },
  mounted: function() {
    this.getEvaluations()
    console.debug("+++++++++++ REQ ++++++++++++++++++")
    const requirements = this.requirements
    for (const reqUID in requirements) {
      alert(requirements[reqUID])
    }
  },

  methods: {
    /* --- store data to local store "Localbase" ------- */
    getEvaluations: function() {
      zozDB.collection('requirements').get()
      .then(requirements => {
        this.requirements = requirements
      })
    }
  }

}
</script>